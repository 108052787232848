import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaGoogle } from 'react-icons/fa';
import './Profile.css';

function Profile() {
  const [userInfo, setUserInfo] = useState({
    name: '',
    age: '',
    address: '',
    gender: '',
    mail: '',
    insta: '',
    phone_no:'',
  });
  const [upcomingClasses, setUpcomingClasses] = useState([]);
  const [enrolledPrograms, setEnrolledPrograms] = useState([]);
  const [completedClasses, setCompletedClasses] = useState([]);
  const [userRole] = useState(localStorage.getItem('role'));

  useEffect(() => {
    fetchUserInfo();
    fetchUpcomingClasses();
    fetchEnrolledPrograms();
    fetchCompletedClasses();
  }, []);

  useEffect(() => {
    console.log('User Info:', userInfo);
  }, [userInfo]);
  
  useEffect(() => {
    console.log('Upcoming Classes:', upcomingClasses);
  }, [upcomingClasses]);


  const fetchUpcomingClasses = async () => {
    try {
      const response = await axios.get('/api/scheduledClasses', {
        params: { status: 'booked' }, // Ensure correct status parameter
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      console.log('Upcoming Classes Response:', response.data);
      const approvedClasses = response.data.filter((cls) => cls.status === 'booked');
      setUpcomingClasses(approvedClasses); // Set state with only approved classes
    } catch (error) {
      console.error('Error fetching scheduled classes:', error.response?.data?.message || 'Error accessing API');
    }
  };  
  
  const fetchCompletedClasses = async () => {
    try {
      const response = await axios.get('/api/completedClasses', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setCompletedClasses(response.data); // Set state with completed classes
    } catch (error) {
      console.error('Error fetching completed classes:', error);
    }
  };
  // Modify the fetchEnrolledPrograms function to handle the API response
const fetchEnrolledPrograms = async () => {
  try {
    const response = await axios.get(`/api/programs/enrolled/${localStorage.getItem('userId')}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    // Use the correct response data structure
    const enrolledPrograms = response.data.enrolledPrograms || [];

    // Update state with the correctly formatted programs array
    setEnrolledPrograms(enrolledPrograms);
  } catch (error) {
    console.error('Error fetching enrolled programs:', error);
  }
};

const fetchUserInfo = async () => {
  try {
    const response = await axios.get('/api/profile/info', {
      params: { userId: localStorage.getItem('userId') }, // Check that userId is correctly retrieved
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    console.log('User Info API Response:', response.data);
    setUserInfo(response.data);
  } catch (error) {
    console.error('Error fetching user info:', error);
  }
};

const handleUpdateProfile = async (event) => {
  event.preventDefault();
  try {
    console.log("within profile update - phone_no",userInfo.phone_no)
    const response = await axios.post('/api/profile/update', {
      userId: localStorage.getItem('userId'),
      ...userInfo,
    });
    console.log('Profile Update Response:', response.data);
    alert(response.data.message);
  } catch (error) {
    console.error('Failed to update profile:', error);
    alert(`Profile update failed: ${error.response?.data?.message || error.message}`);
  }
};

const handleCompleteClass = async (bookingId) => {
  try {
    // Mark the class as completed in the backend
    const response = await axios.post(
      '/api/markCompleted',
      { bookingId },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      }
    );

    // Check if the response is successful
    if (response.data.message) {
      // Immediately remove the class from the upcomingClasses state
      setUpcomingClasses((prevClasses) =>
        prevClasses.filter((cls) => cls.booking_id !== bookingId)
      );

      // Optionally, refresh the completed classes list if needed
      fetchCompletedClasses();
      fetchEnrolledPrograms();

      // Notify user of success
      alert(response.data.message);
    } else {
      alert('Failed to mark the class as completed.');
    }
  } catch (error) {
    console.error('Failed to mark class as complete:', error);
    alert('Error marking class as complete. Please try again.');
  }
};

  const handleConfirmPayment = async (bookingId) => {
    try {
      await axios.post('/api/confirmPayment', { bookingId }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      fetchCompletedClasses(); // Refresh completed classes list
    } catch (error) {
      console.error('Failed to confirm payment:', error);
    }
  };

  const createGoogleCalendarLink = (cls) => {
    const title = `TumbleClubHD Session`;
    const description = `${cls.program_type} - ${cls.name}`;
    const location = 'Online/Physical Location';
  
    // Parsing the class date and setting the time zone to avoid discrepancies
    const classDate = new Date(cls.class_date);
    const formattedDate = classDate.toISOString().split('T')[0].replace(/-/g, ''); // YYYYMMDD
  
    // Extract start and end times from time slot
    const [startTime, endTime] = cls.time_slot.split(' - '); // Split start and end times
  
    // Helper function to convert 12-hour format to 24-hour format without UTC shift
    const convertTo24Hour = (time) => {
      const [timeString, period] = time.split(' ');
      let [hours, minutes] = timeString.split(':');
      hours = parseInt(hours, 10);
      
      if (period === 'PM' && hours !== 12) {
        hours += 12; // Convert PM to 24-hour format
      } else if (period === 'AM' && hours === 12) {
        hours = 0; // Convert 12 AM to 00 in 24-hour format
      }
  
      return `${hours.toString().padStart(2, '0')}${minutes}00`;
    };
  
    // Convert start and end times to 24-hour format without 'Z' suffix for local time interpretation
    const formattedStartTime = convertTo24Hour(startTime);
    const formattedEndTime = convertTo24Hour(endTime);
  
    // Construct start and end date-time without 'Z' to ensure local time is respected
    const startDateTime = `${formattedDate}T${formattedStartTime}`;
    const endDateTime = `${formattedDate}T${formattedEndTime}`;
  
    // Construct the Google Calendar event URL
    return `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(title)}&details=${encodeURIComponent(description)}&location=${encodeURIComponent(location)}&dates=${startDateTime}/${endDateTime}`;
  };
  

  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log(" formfield name", name)
    setUserInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <section className='index'>
    <div className="profile-container" >
      {/* Left Column */}
      <div className="left-column">
      <div className="upcoming-classes">
         <h2>Upcoming Sessions</h2>
         <p className="booking-note">
            * <strong> Note:</strong> Please attend all of your upcoming sessions. If you are unable to do so, kindly notify us via email at least 24 hours before the session. If we do not receive timely notice, you will lose the class and be charged the full session amount.
          </p>
       {upcomingClasses.length > 0 ? (
       <div className="upcoming-classes-list">
      {upcomingClasses.map((cls, index) => (
        <div key={index} className="class-card">
          <p><strong>Name:</strong> {cls.name}</p>
          <a href={createGoogleCalendarLink(cls)} target="_blank" rel="noopener noreferrer" className="google-calendar-btn">
                    <button style={{ backgroundColor: 'goldenrod', color: 'black', border: 'none', padding: '8px 12px', borderRadius: '4px', cursor: 'pointer', position: 'absolute', top: '10px', right: '10px' }}>
                      <FaGoogle style={{ marginRight: '6px' }} /> 
                    </button>
                  </a>
          <p><strong>Program:</strong> {cls.program_type}</p>
          <p><strong>Day:</strong> {cls.day}</p>
          <p><strong>Date:</strong> {new Date(cls.class_date).toLocaleDateString('en-US')}</p>
          <p><strong>Time:</strong> {cls.time_slot}</p>
          
          {/* Display "Mark as Completed" for Admins or Non-Admins if the class belongs to them */}
          {userRole === 'Admin' || cls.unique_id === localStorage.getItem('userId') ? (
            <button onClick={() => handleCompleteClass(cls.booking_id)}>Mark as Completed</button>
          ) : null}
        </div>
      ))}
    </div>
    
   ) : (
    <p>No upcoming Sessions.</p>
    )}
    </div>
      </div>
      <div className="right-column">
        {userRole !== 'Admin' ? (
          <>
            <div className="student-right-top">
              <div className="info-container">
                <h2 className="update">Update Profile</h2>
                <form id="edit-profile" onSubmit={handleUpdateProfile}>
                  <div className="form-row">
                    <input type="text" name="name" placeholder="Name" value={userInfo.name} onChange={handleChange} />
                    <input type="number" name="age" placeholder="Age" value={userInfo.age} onChange={handleChange} />
                  </div>
                  <div className="form-row">
                    <input type="text" name="address" placeholder="Address" value={userInfo.address} onChange={handleChange} />
                    <input type="text" name="gender" placeholder="Gender" value={userInfo.gender} onChange={handleChange} />
                  </div>
                  <div className="form-row">
                    <input type="email" name="email" placeholder="Email" value={userInfo.email} onChange={handleChange} />
                    <input type="text" name="phone_no" placeholder="Phone Number" value={userInfo.phone_no} onChange={handleChange} />
                  </div>
                  <p className="booking-note">
                   * <strong> Note:</strong> Adding your Instagram @ will give us consent to tag you on our page</p>
                  <div className="form-row">
                    <input type="text" name="insta" placeholder="Instagram" value={userInfo.insta} onChange={handleChange} />
                    </div>
                  <button type="submit">Update Profile</button>
                </form>
              </div>
              <div className="enrolled-programs">
  <h2>Enrolled Programs</h2>
  <ul>
    {enrolledPrograms.length > 0 ? (
      enrolledPrograms.map((program, index) => (
        <li key={index}>
          <p><strong>Program:</strong> {program.program_type}</p>
          <p><strong>Sessions Attended:</strong> {program.sessions}</p>
          <p><strong>Total Sessions:</strong> {program.totalClasses}</p>
        </li>
      ))
    ) : (
      <li>No enrolled programs available.</li>
    )}
  </ul>
              </div>

            </div>
            <div className="student-completed-classes">
              <h2>Completed Sessions</h2>
              <div className="completed-classes-list">
                {completedClasses.map((cls, index) => (
                  <div key={index} className="class-card">
                    <p><strong>Name:</strong> {cls.name}</p>
                    <p><strong>Program:</strong> {cls.program_type}</p>
                    <p><strong>Day:</strong> {cls.day}</p>
                    <p><strong>Date:</strong> {new Date(cls.class_date).toLocaleDateString('en-US')}</p>
                    <p><strong>Time:</strong> {cls.time_slot}</p>
                  </div>
                ))}
              </div>
            </div>
          </>
        ) : (
          <>
            {/* Admin View */}
            <div className="admin-completed-classes">
              <h2>Completed Sessions</h2>
              <div className="completed-classes-list">
                {completedClasses.map((cls, index) => (
                  <div key={index} className="class-card">
                    <p><strong>Name:</strong> {cls.name}</p>
                    <p><strong>Program:</strong> {cls.program_type}</p>
                    <p><strong>Day:</strong> {cls.day}</p>
                    <p><strong>Date:</strong> {new Date(cls.class_date).toLocaleDateString('en-US')}</p>
                    <p><strong>Time:</strong> {cls.time_slot}</p>
                    <p><strong>Paid:</strong> {cls.paid ? 'Yes' : 'No'}</p>
                    {cls.paid === 0 && (
                      <button onClick={() => handleConfirmPayment(cls.booking_id)}>Confirm Payment</button>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
    </section>
  );
}

export default Profile;

