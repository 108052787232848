import React, { useRef } from 'react';
import emailjs from 'emailjs-com';
import './Home.css'; // Ensure this file is created and contains your styles

const Home = () => {
  // Initialize the form reference
  const form = useRef(null);

  // Email sending service
  const sendEmail = (e) => {
    e.preventDefault();

    if (!form.current) {
      console.error('Form reference is undefined');
      return;
    }

    emailjs
      .sendForm('service_mudlat2', 'template_0eeeyrf', form.current, 'ir4f0wzGEXDABtV2f')
      .then(
        () => {
          console.log(form.current);
          console.log('SUCCESS!');
          alert('We have received your appointment. You will receive a mail soon with more details.');
          form.current.reset();
        },
        (error) => {
          console.log('FAILED...', error.text);
          alert('There was an issue submitting your form. Please try again.');
        }
      );
  };
  return (
    <>
      <section id="index" className="container">
        <div className="image-container">
          <img src={`${process.env.PUBLIC_URL}/image/image5.jpeg`} className="card-img-top" alt="home page image" />
        </div>
        <div className="text-container">
          <h2>TumbleClubHD</h2>
          <p>
          Embark on an exhilarating journey to conquer your fitness aspirations alongside me as I elevate your athleticism as you delve into mastering the art of tumbling, refining your acrobatic prowess, and igniting your dance finesse. Unlock the secrets to a dynamic and versatile skillset in gymnastics, guided by seasoned professionals dedicated to sculpting. Join me in sculpting a healthier, stronger, and more agile version of yourself as I propel you towards unparalleled heights of physical excellence and personal achievement. 
          </p>
        </div>
      </section>
      <section id="features">
      <h1>FEATURES</h1>
        <div className="container">
          <div className="row feature-row">
            <div className="col1">
              <div className="fea-box gradient1">
                <h3>HOLISTIC APPROACH</h3>
                <p>
                We seamlessly integrate Acrobatics/Acro, Cheer Tumbling, and Personal Fitness into a cohesive curriculum. We believe in a holistic approach that nurtures physical strength, flexibility, coordination, and artistic expression, ensuring our students develop into well-rounded performers. Over time, students can move from a beginner to an advanced curriculum. We emphasize the importance of artistry and technique in our approach to each program, allowing each student to express their individuality. Our curriculum fosters a balanced development of strength and creativity, preparing students for both competitive performance and personal growth. 
    </p>
              </div>
              <div className="logo-image">
        <img src={`${process.env.PUBLIC_URL}/image/logo.jpg`} alt="Logo" />
        <img src={`${process.env.PUBLIC_URL}/image/logo.jpg`} alt="Logo" />
      </div>
              <div className="fea-box gradient1" >
                <h3>SAFETY</h3>
                <p>
      With a primary focus on safety, we meticulously plan and execute different routines so that students don’t have to worry and can fully immerse themselves in their practice. From proper warm-ups to skill progressions tailored to individual abilities, we prioritize injury prevention and ensure every student is equipped to perform safely. We observe and adjust techniques as needed, reducing the risk of injuries while encouraging students to push beyond their limits. We aim to create a safe environment where students can challenge themselves and grow at their own pace. 
    </p>
              </div>
            </div>
            <div className="col1 image-box">
              <img src={`${process.env.PUBLIC_URL}/image/holistic.jpg`} className="card-img-top" alt="holistic" />
            </div>
          </div>
          <div className="row feature-row">
            <div className="col1 image-box">
              <img src={`${process.env.PUBLIC_URL}/image/skill.jpg`} className="card-img-top" alt="skill" />
            </div>
            <div className="col1">
              <div className="fea-box gradient2">
                <h3>SKILL PROGRESS</h3>
                <p>
                The instructor utilizes structured skill progressions to guide students from foundational techniques to advanced maneuvers. Whether it's mastering basic acrobatic flips or executing intricate sequences with various elements or even getting to your desired goal at the gym, our step-by-step approach ensures students build confidence and proficiency from the beginner level to the advanced level. We tailor our teaching approach to meet the needs and goals of each individual, fostering a supportive learning environment where every student can thrive and excel. 
                </p>
              </div>
              <div className="logo-image">
               <img src={`${process.env.PUBLIC_URL}/image/logo.jpg`} alt="Logo" />
               <img src={`${process.env.PUBLIC_URL}/image/logo.jpg`} alt="Logo" />
             </div>
              <div className="fea-box gradient2">
                <h3>ARTISTRY AND TECHNIQUE</h3>
                <p>
                Beyond mastering physical skills, we emphasize the importance of artistry and technique in our approach to dance, acrobatics, and tumbling. Creativity has no boundaries. We instill an understanding of body alignment, musicality, and storytelling, empowering students to express themselves creatively while honing their technical prowess and taking massive leaps. Recognizing that every student has unique strengths and areas for growth helps us instructors provide personalized attention and feedback.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section><h4>HOW TO JOIN?</h4></section> 
      <section id="trial" className="container text-center my-5 d-flex align-items-center justify-content-between">
        <div className="trial-info p-4 text-warning bg-dark rounded flex-fill">
          <h7 className="mb-3">Trial Session</h7>
          <p className="lead mb-3">
            Experience our professional training program with a one-time trial session before committing to the full course.
          </p>
          <ul className="list-unstyled mb-3">
            <li>✔ Flexibility Check</li>
            <li>✔ Strength Check</li>
            <li>✔ Movement Check</li>
            <li>✔ Balance Check</li>
          </ul>
          <p>If you do well in the trial, you'll be placed in the best program to suit your abilities!</p>
          <p className="registration-fee fw-bold">Registration Fee for Trial Session/Insurance: $99.99</p>
          <small>Note: This trial session is designed to evaluate your abilities and recommend the most suitable program.</small>

          {/* Booking Form with email sending */}
          <form ref={form} className="booking-form" onSubmit={sendEmail}>
            <h2>Book Your Trial Now!</h2>
            <div className="booking-form-group">
              <label className="booking-form-label">Type of Program</label>
              <div className="booking-checkbox-group">
                <label className="checkbox-btn" htmlFor="acrobatics">
                  <input id="acrobatics" type="checkbox" name="programType" value="acrobatics" />
                  <span className="checkmark"></span>
                  <label htmlFor="acrobatics">Acrobatics/Acro</label>
                </label>
                <label className="checkbox-btn" htmlFor="tumbling">
                  <input id="tumbling" type="checkbox" name="programType" value="tumbling" />
                  <span className="checkmark"></span>
                  <label htmlFor="tumbling">Cheer Tumbling</label>
                </label>
                <label className="checkbox-btn" htmlFor="fitness">
                  <input id="fitness" type="checkbox" name="programType" value="fitness" />
                  <span className="checkmark"></span>
                  <label htmlFor="fitness">Personal Fitness</label>
                </label>
              </div>
            </div>

            <div className="booking-form-group">
              <label htmlFor="name">Name:</label>
              <input type="text" className="booking-form-control" id="name" name="name" required />
            </div>
            <div className="booking-form-group">
              <label htmlFor="email">Email:</label>
              <input type="email" className="booking-form-control" id="email" name="email" required />
            </div>
            <div className="booking-form-group">
              <label htmlFor="phone">Phone Number:</label>
              <input type="tel" className="booking-form-control" id="phone" name="phone" required />
            </div>
            <div className="booking-form-group">
              <label htmlFor="message">Message:</label>
              <textarea className="booking-form-control" id="message" name="message" required />
            </div>
            <button type="submit" className="booking-btn">Book Now</button>
          </form>
        </div>
        <div className="trial-image flex-fill">
          <img src={`${process.env.PUBLIC_URL}/image/trial_session.jpg`} className="card-img-top" alt="Trial Session" />
          <img src={`${process.env.PUBLIC_URL}/image/introimage.jpg`} className="card-img-top" alt="Trial Session" />
        </div>
      </section>
      
  <section id="PROGRAMS" className="container py-5">
    <h1 className="text-allign-center mb-4">In Our Library</h1>
    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
      <div className="col">
        <div className="card h-200 w-100">
          <div className="card-inner">
            <div className="card-front">
              <img src={`${process.env.PUBLIC_URL}/image/acrobatics.jpg`} className="card-img-top" alt="Acrobatics" />
              <img src={`${process.env.PUBLIC_URL}/image/acrocoach.jpg`} className="card-img-top" alt="Acrobatics Coach" />
              
            </div>
            <div className="card-back">
              <div className="back-section">
                <h5 className="card-title">Acrobatics/Acro - Beginner</h5>
                <p className="card-text">Beginner</p>
                <p className="card-text">Introduction to basic acrobatic skills.</p>
                <p className="card-text">Focus on fundamental techniques.</p>
                <div className="price">$120 per session</div>
                <p className="card-text">Sessions - 5/10 sessions or Join the Club for Exclusive deals </p>
              </div>
              <div className="back-section">
                <h5 className="card-title">Acrobatics/Acro - Advanced</h5>
                <p className="card-text">Advanced</p>
                <p className="card-text">Challenging and rewarding.</p>
                <p className="card-text">Expands on basic skills.</p>
                <div className="price">$120 per session</div>
                <p className="card-text">Sessions - 5/10 sessions or Join the Club for Exclusive deals </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col">
        <div className="card h-200 w-100">
          <div className="card-inner">
            <div className="card-front">
              <img src={`${process.env.PUBLIC_URL}/image/tumbling.jpg`} className="card-img-top" alt="Tumbling" />
              <img src={`${process.env.PUBLIC_URL}/image/tumblecoach.jpg`} className="card-img-top" alt="Tumbling Coach" />
              
            </div>
            <div className="card-back">
              <div className="back-section">
                <h5 className="card-title">Cheer Tumbling - Beginner</h5>
                <p className="card-text">Beginner</p>
                <p className="card-text">Basic tumbling skills and techniques.</p>
                <p className="card-text">Safe and fun environment.</p>
                <div className="price">$120 per session</div>
                <p className="card-text">Sessions - 5/10 sessions or Join the Club for Exclusive deals</p>
              </div>
              <div className="back-section">
                <h5 className="card-title">Cheer Tumbling - Advanced</h5>
                <p className="card-text">Advanced</p>
                <p className="card-text">Challenging and rewarding.</p>
                <p className="card-text">Expands on basic skills.</p>
                <div className="price">$120 per session</div>
                <p className="card-text">Sessions - 5/10 sessions or Join the Club for Exclusive deals </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col">
        <div className="card h-200 w-100">
          <div className="card-inner">
            <div className="card-front">
              <img src={`${process.env.PUBLIC_URL}/image/fitness.JPG`} className="card-img-top" alt="Personal Fitness" />
              <img src={`${process.env.PUBLIC_URL}/image/fitnesscoach.jpeg`} className="card-img-top" alt="Personal Fitness Coach" />
              
            </div>
            <div className="card-back">
             
              <div id="pf" className="back-section">
                <h5 className="card-title">Personal Fitness - Beginner</h5>
                <p className="card-text">Beginner</p>
                <p className="card-text">Basic fitness training for beginners.</p>
                <p className="card-text">Focus on building a strong foundation.</p>
                <p className="card-text">Sessions - 5/10 sessions or Join the Club for Exclusive deals</p>
                <div className="price">$125 per session</div>
              </div>
              <div id="pf" className="back-section">
                <h5 className="card-title">Personal Fitness - Advanced</h5>
                <p className="card-text">Advanced</p>
                <p className="card-text">Challenging and rewarding.</p>
                <p className="card-text">Personalized and intensive workouts.</p>
                <p className="card-text">Sessions - 5/10 sessions or Join the Club for Exclusive deals </p>
                <div className="price">$125 per session</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p className="booking-note">
     To get more information and see what you get to learn, go to the Programs section.
    </p>
  </section>
  
  <div className="podcast">
      <img src={`${process.env.PUBLIC_URL}/image/podcast.jpeg`} className="pod-img" alt="pod1" />
      <div className="pod-content">
          <p className="pod-head">Every path to the podium is unique, filled with challenges and triumphs, but with determination, support, and the right mindset, anything is possible.</p>
          <p className="pod-body">This podcast illuminates the journeys of cheerleaders, dancers, and athletes of all disciplines as they strive for excellence. We dive deep into the stories of incredible athletes, uncovering the secrets behind their early beginnings, rigorous training routines, and significant milestones. We explore the vital support systems that uplift them, the obstacles they overcome, and the personal growth and valuable lessons learned along the way. Whether you're an aspiring athlete or a seasoned pro, "Path to Podium" offers inspiration, advice, and a sense of community. </p>
          <p className="pod-out">Don't forget to subscribe, leave a review, and share this podcast with fellow athletes and enthusiasts for more stories from remarkable athletes who continue to push boundaries and redefine success.</p>
      </div>
  </div>
      </>
    );
  };

  export default Home;