// Navbar.js

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [name, setName] = useState('');  
  const [role, setRole] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const storedName = localStorage.getItem('name');
    const token = localStorage.getItem('token');
    const storedRole = localStorage.getItem('role');
    if (storedName && token) {
      setIsLoggedIn(true);
      setName(storedName);
      setRole(storedRole);
    }
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    navigate('/login');
    window.location.reload();
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-brand">
        <img src={`${process.env.PUBLIC_URL}/image/logo.jpg`} alt="Logo" />
      </div>
      <div className={`menu-icon ${menuOpen ? 'change' : ''}`} onClick={toggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
      <ul className={`navbar-nav ${menuOpen ? 'active' : ''}`}>
        <li className="nav-item">
          <Link className="nav-link" to="/" onClick={() => setMenuOpen(false)}>HOME</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/program" onClick={() => setMenuOpen(false)}>
            {role === 'Admin' ? 'STUDENTS' : 'PROGRAMS'}
          </Link>
        </li>
        {isLoggedIn && (
          <li className="nav-item">
            <Link className="nav-link" to="/scheduler" onClick={() => setMenuOpen(false)}>
              {role === 'Admin' ? 'CHECK BOOKINGS' : 'BOOK SESSIONS'}
            </Link>
          </li>
        )}
        <li className="nav-item">
          <Link className="nav-link" to="/about" onClick={() => setMenuOpen(false)}>ABOUT</Link>
        </li>
        {name ? (
          <li className="nav-item dropdown">
            <span className="nav-link dropdown-toggle">
              Hello, {name}
            </span>
            <div className="dropdown-content">
              <Link className="dropdown-item" to="/profile" onClick={() => setMenuOpen(false)}>View Profile</Link>
              <a className="dropdown-item" href="#" onClick={(e) => { e.preventDefault(); handleLogout(); setMenuOpen(false); }}>Logout</a>
            </div>
          </li>
        ) : (
          <li className="nav-item">
            <Link className="nav-link loginBtn" to="/login" onClick={() => setMenuOpen(false)}>LOGIN/SIGNUP</Link>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default Navbar;
